import loadable from "@loadable/component";
import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";
import Header from "../header";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

const TrackPageView = loadable(() => import("../track-page-view"));

const Footer = loadable(() => import("../footer"));

export default function RootLayout(): JSX.Element {
  const [searchParams] = useSearchParams();

  const captureUTMs = () => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: params.get("utm_source"),
      utm_medium: params.get("utm_medium"),
      utm_campaign: params.get("utm_campaign"),
      utm_content: params.get("utm_content"),
      utm_term: params.get("utm_term"),
    };

    // Store UTM parameters in localStorage (or cookies)
    localStorage.setItem("utm_params", JSON.stringify(utmParams));
  };

  useEffect(() => {
    const campaignName = searchParams.get("c");
    if (campaignName) {
      document.cookie = `campaign=${campaignName}; path=/; max-age=3600; domain=thunderproxy.com`;
    }

    const gclid = searchParams.get("gclid");

    if (gclid) {
      document.cookie = `gclid=${gclid}; path=/; max-age=3600; domain=thunderproxy.com`;
    }

    captureUTMs();
  }, [searchParams]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: import.meta.env.VITE_GTM_TRACKING_ID!,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="bg-background h-full">
      <div className="relative min-h-screen">
        <Header />
        <Outlet />
        <ScrollRestoration />
      </div>
      <Footer />
      <TrackPageView />
    </div>
  );
}
